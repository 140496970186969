import axios from 'axios';

// const axiosServices = axios.create({ baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3010/' });
// const axiosServices = axios.create({ baseURL: 'http://localhost:8800/api/' });
// const axiosServices = axios.create({ baseURL: 'https://node.gokarobaar.com/api/' });
// const axiosServices = axios.create({ baseURL: 'https://backend.inspectnbuy.com/api/' });
const axiosServices = axios.create({ baseURL: 'https://dev-api.inspectnbuy.com/api/' });

// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

// axiosServices.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401 && !window.location.href.includes('/login')) {
//       window.location.pathname = '/login';
//     }
//     return Promise.reject((error.response && error.response.data) || 'Wrong Services');
//   }
// );

export default axiosServices;
